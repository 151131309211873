
import './App.css';
import Layout from './hoc/Layout';

function App() {
  return (
 <Layout />
  );
}

export default App;